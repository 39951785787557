<template>
  <diva-card :filterClasses="['orders-table__filter-container']">
    <template v-slot:filters>
      <div class="orders-table__count" v-if="ordersCount && filter.studio !== 'models'">
        <div class="orders-table__count-item" @click="setFilterStatus('all')">
          <p class="mb-0">{{getTrans('messages.total')}} </p>
          <p class="mb-0 pl-2 font-weight-bold">{{ordersCount.total}}</p>
        </div>
        <div class="orders-table__count-item" @click="setFilterStatus('active')">
          <p class="mb-0">{{getTrans('messages.order_status_1')}}</p>
          <p class="mb-0 pl-2 font-weight-bold">{{ordersCount.active}}</p>
        </div>
        <div class="orders-table__count-item"  @click="setFilterStatus('paused')">
          <p class="mb-0">{{getTrans('messages.order_status_0')}}</p>
          <p class="mb-0 pl-2 font-weight-bold">{{ordersCount.pause}}</p>
        </div>
        <div class="orders-table__count-item"  @click="setFilterStatus('completed')">
          <p class="mb-0">{{getTrans('messages.order_status_4')}}</p>
          <p class="mb-0 pl-2 font-weight-bold">{{ordersCount.completed}}</p>
        </div>
        <div class="orders-table__count-item"  @click="setFilterStatus('cancelled')">
          <p class="mb-0">{{getTrans('messages.order_status_2')}}</p>
          <p class="mb-0 pl-2 font-weight-bold">{{ordersCount.cancel}}</p>
        </div>
      </div>

      <div class="orders-table__filter">
        <div class="filters__item" v-if="can('studio-owner')">
          <button-radio-group :options="studioOptions"
            button-classes="btn-secondary btn-sm"
            v-model="filter.studio">
          </button-radio-group>
        </div>
        <div class="filters__item">
          <label class="filters__select-label">{{getTrans('messages.status')}}:</label>
          <ElSelect v-model="filter.status" :class="'filters__select'">
            <ElOption
              v-for="[index, option] in filterStatuses"
              :class="'filterOptionClass'"
              :value="index"
              :key="index"
              :label="option"
            >{{option}}</ElOption>
          </ElSelect>
        </div>
        <div class="filters__item">
          <label class="filters__select-label">{{getTrans('messages.plan')}}:</label>
          <ElSelect v-model="filter.plan_id" :class="'filters__select'" filterable>
            <ElOption
              v-for="option in filterPlans"
              :class="'filterOptionClass'"
              :value="option.id"
              :key="option.id"
              :label="option.name"
            >{{option.name}}</ElOption>
          </ElSelect>
        </div>
      </div>
    </template>

    <content-loader v-if="loading"></content-loader>
    <template v-else>
      <store-pagination v-if="showPagination" :store="'Order'" @update:modelValue="loadData"></store-pagination>
      <div class="orders-table__container" role="table">
        <div class="orders-table__row-group header" role="row-group">
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.item_created') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.destination') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.plan') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.status') }}</div>
          <div class="orders-table__row" role="column-header">{{ getTrans('messages.actions') }}</div>
        </div>
        <div v-for="order in orders" :key="order.id" class="orders-table__row-group" role="rowgroup">
          <OrderRow :order="order"></OrderRow>
        </div>
      </div>
      <store-pagination v-if="showPagination" :store="'Order'" @update:modelValue="loadData"></store-pagination>
    </template>
  </diva-card>
</template>
<script>

import { mapGetters } from "vuex";
import {ElSelect, ElOption} from 'element-plus';
import Filters from "@/mixins/Filters";
import OrderRow from "./Components/OrderRow";
import StorePagination from "@/components/StorePagination";
import ButtonRadioGroup from "@/components/ButtonRadioGroup";

export default {
  name: 'orders-page',
  mixins: [Filters],
  components: {
    OrderRow,
    StorePagination,
    ElSelect,
    ElOption,
    ButtonRadioGroup
  },
  data() {
    return {
      filter: {
        studio: 'owner',
        status: 'all',
        plan_id: ''
      },
      studioOptions: [
        {value: 'owner', label: this.getTrans('messages.studio_orders')},
        {value: 'models', label: this.getTrans('messages.model_orders')}
      ]
    };
  },
  computed: {
    ...mapGetters('Order', {
      loading: 'listLoading',
      orders: 'getList',
      pagination: 'pagination',
      getData: 'getDataByName',
      statuses: 'statuses'
    }),
    ...mapGetters('User', {
      can: 'can',
    }),
    ordersCount() {
      return this.getData('ordersCount');
    },
    showPagination() {
      return this.orders?.length > 0;
    },
    filterStatuses() {
      const statuses = new Map();
      statuses.set('all', this.getTrans('messages.all'));
      for (let status in this.statuses) {
        statuses.set(status, this.getTrans('messages.order_status_' + status));
      }
      return statuses;
    },
    filterPlans() {
      let plans = this.$store.getters['Plan/plansShort'];
      if (plans instanceof Array) {
        // Clone array, so store value will not be updated.
        plans = [...plans];
        plans.unshift({id: '', name: this.getTrans('messages.all')});
      }

      return plans;
    }
  },
  methods: {
    loadData(page = 1) {
      this.$store.dispatch('Order/getOrders', {
        page: page,
        filter: this.collectFilters(),
      });
    },
    setFilterStatus(statusName) {
      const key = Object.keys(this.statuses).find(key => this.statuses[key] === statusName);
      this.filter.status = key || 'all';
    }
  },
  mounted() {
    this.loadData();
    this.$store.dispatch('Order/getOrdersCount');
    this.$store.dispatch('Plan/getPlansShort');
  },
  watch: {
    filter: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
};

</script>
<style scoped>
.divider {
  border-right: solid;
}
</style>
