<template>
  <div class="btn-group-toggle" data-toggle="buttons">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="btn"
      :class="[{ active: modelValue === option.value }, buttonClasses]"
    >
      <input
        name="btn_radio"
        :value="option.value"
        v-model="model"
        type="radio"
        :id="'option' + index"
        autocomplete="off"
      />
      {{ option.label }}
    </label>
  </div>
</template>
<script>
export default {
  name: "button-radio-group",
  emits: ['update:modelValue'],
  props: {
    options: {
      type: Array,
      description:
        'Radio options. Should be an array of objects {value: "", label: ""}',
      default: () => [],
    },
    modelValue: {
      type: String,
      description: "Radio value",
    },
    buttonClasses: {
      type: [String, Object],
      description: "Inner button css classes",
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
};
</script>
<style></style>
