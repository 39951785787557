<template>
  <div v-if="showUser" class="orders-table__row">
    <p v-if="userData && userData.email" class="orders-table__cell-name">{{ userData.name }}</p>
    <p v-if="userData && userData.name" class="orders-table__cell-aditional-text">{{ userData.name }}</p>
  </div>
  <div v-else class="orders-table__row">
    <p class="orders-table__cell-name">{{order.title}}</p>
    <p class="orders-table__cell-aditional-text">{{toDateTime(order.created_at)}}</p>
  </div>
  <div class="orders-table__row">
    <profiles-avatar :profiles="profiles"></profiles-avatar>
  </div>
  <div class="orders-table__row">
    <div v-if="true">
      <p class="orders-table__cell-name">{{order?.plan?.data?.name || getTrans('messages.deleted_plan')}}</p>
      <p class="orders-table__cell-aditional-text">
        <a href="" @click.prevent="openPlanInfo">
        {{getTrans('messages.plan_details')}}
        <i class="far fa-question-circle"></i>
        </a>
      </p>
    </div>
    <div v-else>
      <p class="orders-table__cell-name">order.plan.data.name</p>
      <p class="orders-table__cell-aditional-text">{{getTrans('messages.deleted_plan')}}</p>
    </div>
  </div>
  <div class="orders-table__row">
    <div class="orders-table__progress-completion">
      {{
      getTrans('messages.spent_tokens', {
      'spent': usdToTokens(order.spent),
      'total': usdToTokens(order.paid)
      })
      }}
    </div>
    <div class="d-flex align-items-center">
      <base-progress
        :type="progressType(order)"
        :height="3"
        :value="progress(order)"
        :class="'w-100'"
        :progressClasses="'mb-0'">
      </base-progress>
      <div v-if="order" class="orders-table__progress-indicator">
        {{progressPercent(order)}}
      </div>
    </div>
    <div class="orders-table__progress-status" :class="'text-' + progressType(order)">
      <component :is="endless(order) ? 'el-tooltip' : 'div'" placement="top" :content="getTrans('messages.renew_forever')">
        <div>{{statusStr(order)}} <span v-if="showRenewals(order)" v-html="renewals(order)"></span></div>
      </component>
      <br/>
      <span v-if="readyForRenew(order) && statuses[order.status] !== 'pending'">{{autoRenewIn(order)}}</span>
    </div>
  </div>
  <div class="orders-table__row">
    <div class="d-flex orders-table__actions">
      <order-actions :order="order"></order-actions>
    </div>
  </div>
</template>
<script>

import BaseProgress from "@/components/BaseProgress.vue";
import ProfilesAvatar from "./ProfilesAvatar";
import { mapGetters } from "vuex";
import DateFormat from "@/mixins/DateFormat";
import StringFormatter from "@/mixins/StringFormatter";
import OrderActions from "./OrderActions";
import OrderStatus from "@/views/Orders/Mixins/OrderStatus";

export default {
  name: "orderrow",
  components: {
    BaseProgress,
    ProfilesAvatar,
    OrderActions
  },
  mixins: [DateFormat, StringFormatter, OrderStatus],
  props: ['order', 'showUser'],
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    ...mapGetters('Order', ['statuses']),
    userData() {
      if (this.order.user.data) {
        return this.order.user.data
      } else {
        return this.order.user;
      }
    },
    profiles() {
      return this.order?.profilesList?.data;
    },
  },
  methods: {
    openPlanInfo() {
      console.log('packages view page will be created')
      //this.$router.push('/packages/view/'+ this.order.plan.data.id);
    },
  }
};
</script>
