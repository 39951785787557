<script>
import moment from "moment";

export default {
  methods: {
    toDateTime(string) {
      return moment(string).format('MMM Do YYYY, HH:mm');
    }
  }
};
</script>
